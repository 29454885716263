import { DeepPartial } from '@empiriecom/module-components/utils/interfaces';
import deepmerge from '@/src/utils/deepmergeconfig';
import { tenantConfig } from '@empiriecom/mybuy-components/config/ottonl';
import defaultConfig from './default';
import { FragmentConfig } from './types';

const ottonlconfig: DeepPartial<FragmentConfig> = {
  ...tenantConfig,
  locales: ['nl-NL'],
  l10n: {
    'nl-NL': ['base-nl', 'ottonl-nl'],
  },
  hidePriceSign: true,
  companyId: 'ottonl',
  tracking: {
    gtmId: 'GTM-5J9J459',
    soastaId: 'TWCKT-SC956-AY2DD-K9G3Y-24R2M',
  },
  permissions: {
    newsletterEnabled: true,
    eInvoiceEnabled: false,
    postalBankStatementEnabled: true,
    neighboursDeliveryEnabled: true,
    smsNotificationEnabled: true,
  },
  personalData: {
    isIbanEditable: true,
    isNL: true,
  },
  shipmentInfos: {
    hideTrackingIdWithoutUrl: true,
  },
  paylink: {
    ...tenantConfig.paylink,
    enforced: true,
    enabled: true,
  },
  show: {
    ibanBeforeBic: true,
    changeToEmailLogin: false,
    lastName: true,
    isServiceContactTileEnabled: false,
  },
  lounge: {
    banner: (language: string, device: string) =>
      `https://bilder.ottoversand.at/empiriecom/io/ottoversandat/${language}/lounge_banner_${device}.jpg`,
    premiumContentSubscription: (language: string) =>
      `https://fragment-content-external.ottoversandat.empirie.cloud/mein-konto/premium-subscription-${language}`,
    premiumContentDummy: (language: string) =>
      `https://fragment-content-external.ottoversandat.empirie.cloud/mein-konto/premium-dummy-${language}`,
    premiumOnTop: true,
  },
  images: {
    logoSmall: {
      default: 'https://bilder.baur.de/i/empiriecom/otto_logo_small.png',
    },
  },
  showHonorificForAddress: false,
  einvoice: {
    showReclaimLink: false,
  },
};

export default deepmerge(defaultConfig, ottonlconfig) as FragmentConfig;
